import { Layout, Menu as AntMenu } from "antd";
import type { MenuProps } from "antd";
import { useState } from "react";
import {
  ApartmentOutlined,
  AppstoreOutlined,
  AppstoreAddOutlined,
  AuditOutlined,
  ClusterOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

const { Sider } = Layout;

function Menu() {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const items: MenuProps["items"] = [
    {
      label: <Link to="/hub/interface-groups">Hub</Link>,
      key: "hub/interface-groups",
      icon: <ApartmentOutlined />,
    },
    {
      label: <Link to="/actions">Actions</Link>,
      key: "actions",
      icon: <AppstoreAddOutlined />,
    },
    {
      label: <Link to="/composed-actions">Composed Actions</Link>,
      key: "composed-actions",
      icon: <ClusterOutlined />,
    },
    // {
    //   label: (
    //     <Link to="/workflow">Pipelines</Link>
    //   ),
    //   key: "workflow",
    //   icon: <ContainerOutlined />,
    // },
    {
      label: <Link to="/typeinstances">TypeInstances</Link>,
      key: "typeinstances",
      icon: <AppstoreOutlined />,
    },
    {
      label: <Link to="/policy">Policy</Link>,
      key: "policy",
      icon: <AuditOutlined />,
    },
    {
      label: <Link to="/role">Role</Link>,
      key: "role",
      icon: <UsergroupAddOutlined />,
    },
  ];

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed) => setCollapsed(collapsed)}
      className="site-layout-background"
    >
      <AntMenu
        mode="inline"
        defaultSelectedKeys={[location.pathname.substring(1)]}
        items={items}
      />
    </Sider>
  );
}

export default Menu;
